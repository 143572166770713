<template>
  <div style="text-align: right;height: 48px;">
    <el-button @click="openUserInfoDialog">编辑</el-button>
  </div>
  <el-card class="radius-20 outer-card">
    <el-descriptions :column="2" border v-if="user" style="font-size:20px;">
      <el-descriptions-item label="账号">{{
        user.account
      }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{
        user.tel
      }}</el-descriptions-item>
      <el-descriptions-item label="密码">{{user.password
      }}</el-descriptions-item>
      <el-descriptions-item label="列表数量">{{user.listCount}}</el-descriptions-item>
      <el-descriptions-item label="充值数量">{{
        user.cardCount
      }}</el-descriptions-item>
      <el-descriptions-item label="状态">{{processState()}}</el-descriptions-item>
      <el-descriptions-item label="充值时间">{{user.useCard}}</el-descriptions-item>
      <el-descriptions-item label="过期日期">{{user.expDate}}</el-descriptions-item>
      <el-descriptions-item label="最大人数">{{user.maxUser}}</el-descriptions-item>
      <el-descriptions-item label="动态分配在线人数">
        {{ user.dynamicDiyUser ? "是" : "否" }}
      </el-descriptions-item>
      <el-descriptions-item label="上浮比例">{{user.increaseRatio}}</el-descriptions-item>
      <el-descriptions-item label="充值卡号">{{user.cardNum}}</el-descriptions-item>
    </el-descriptions>
    <el-skeleton :rows="20" animated v-else />
  </el-card>
  <UserInfoDialog ref="userInfoDialog" :show="showUserInfoDialog" :close="closeUserInfoDialog" @submit="editUserInfo" />
</template>

<script>
import * as Api from "@/api";
import UserInfoDialog from "@/components/UserInfoDialog.vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    return {
      user: Api.getUserInfoByNameRef(route.params.account),
    };
  },
  data() {
    return {
      showUserInfoDialog: false,
    };
  },
  methods: {
    openUserInfoDialog() {
      this.showUserInfoDialog = true;
      this.$refs.userInfoDialog.setInit(this.user);
    },
    closeUserInfoDialog() {
      this.showUserInfoDialog = false;
    },
    processState() {
      return Api.processUserState(this.user.state).value;
    },
    editUserInfo(form) {
      let {
        id: userId,
        listCount,
        state,
        qq,
        password,
        expDate,
        maxUser,
        increaseRatio,
        tel
      } = form;
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.setUserInfoApi({
        userId,
        listCount,
        state,
        qq,
        password,
        expDate,
        maxUser,
        increaseRatio,
        tel
      })
        .then(() => {
          return this.refresh();
        })
        .then(() => {
          Api.sucessMessage("操作成功");
        })
        .finally(() => {
          this.showUserInfoDialog = false;
          loading.close();
        });
    },
    refresh() {
      return Api.getUserInfoByNameApi(this.user.account).then(({ data }) => {
        this.user = data.data;
      });
    },
  },
  components: {
    UserInfoDialog,
  },
};
</script>

<style scoped>
.float-btn {
  position: absolute;
  top: 16px;
  right: 36px;
}
</style>