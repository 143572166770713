<template>
  <el-dialog
    title="编辑"
    v-model="show"
    :before-close="beforeClose"
    width="400px"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      style="width: 300px"
      class="mx-auto"
    >
      <el-form-item label="qq：" prop="qq">
        <el-input v-model="form.qq"></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="tel">
        <el-input v-model="form.tel"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="列表数量：" prop="listCount">
        <el-input-number v-model.number="form.listCount" />
      </el-form-item>
      <el-form-item label="状态：" prop="state">
        <el-select v-model.number="form.state">
          <el-option label="正常" :value="0" />
          <el-option label="禁用" :value="1" />
          <el-option label="审核" :value="2" />
          <el-option v-if="form.state !== 0" label="测试" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="到期日期" prop="expDate">
        <el-date-picker
          v-model="form.expDate"
          type="datetime"
          placeholder="选择日期时间"
          value-format="YYYY-MM-DD HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="上浮比例：" prop="increaseRatio">
        <el-input-number v-model.number="form.increaseRatio" />
      </el-form-item>
      <el-form-item label="最大人数：" prop="maxUser">
        <el-input-number v-model.number="form.maxUser" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="$emit('submit', form)"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  emits: ["submit"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    setInit(user) {
      this.form = Object.assign({}, user);
    },
    beforeClose(done) {
      this.close();
      done();
    },
  },
};
</script>